/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RaffleUser } from './RaffleUser';

export type RaffleViewModel = {
  id: string;
  creator: RaffleUser;
  owner: boolean;
  type: RaffleViewModel.type;
  status: RaffleViewModel.status;
  /**
   * GreenBox: GreenBox<br/>BlueBox: BlueBox<br/>RedBox: RedBox<br/>BronzeBox1: BronzeBox 1<br/>BronzeBox2: BronzeBox 2<br/>BronzeBox3: BronzeBox 3<br/>BronzeBox4: BronzeBox 4<br/>BronzeBox5: BronzeBox 5<br/>BronzeBox6: BronzeBox 6<br/>SilverBox1: SilverBox 1<br/>SilverBox2: SilverBox 2<br/>SilverBox3: SilverBox 3<br/>SilverBox4: SilverBox 4<br/>SilverBox5: SilverBox 5<br/>SilverBox6: SilverBox 6<br/>GoldBox1: GoldBox 1<br/>GoldBox2: GoldBox 2<br/>GoldBox3: GoldBox 3<br/>GoldBox4: GoldBox 4<br/>GoldBox5: GoldBox 5<br/>GoldBox6: GoldBox 6<br/>Emerald1: Emerald 1<br/>Emerald2: Emerald 2<br/>Emerald3: Emerald 3<br/>Ruby1: Ruby 1<br/>Ruby2: Ruby 2<br/>Ruby3: Ruby 3<br/>Diamond1: Diamond 1<br/>Diamond2: Diamond 2<br/>Diamond3: Diamond 3<br/>Yellow1: Yellow1<br/>Yellow2: Yellow2<br/>Yellow3: Yellow3<br/>Yellow4: Yellow4<br/>Yellow5: Yellow5<br/>Yellow6: Yellow6<br/>Green1: Green1<br/>Green2: Green2<br/>Green3: Green3<br/>Green4: Green4<br/>Green5: Green5<br/>Green6: Green6<br/>Orange1: Orange1<br/>Orange2: Orange2<br/>Orange3: Orange3<br/>Orange4: Orange4<br/>Orange5: Orange5<br/>Orange6: Orange6
   */
  prize: string;
  totalTickets: number;
  winnerCount: number;
  prizePerWinner: number;
  ticketPrice: number;
  totalAmount: number;
  prizeAmount: number;
  ticketsBought: number;
  myTicketsBought: number;
  createdAt: string;
  completedAt: string | null;
  winners: Array<RaffleUser>;
};

export namespace RaffleViewModel {
  export enum type {
    PUBLIC = 'public',
    PRIVATE = 'private',
  }

  export enum status {
    CANCELED = 'canceled',
    ACTIVE = 'active',
    COMPLETED = 'completed',
  }
}
