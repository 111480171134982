/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BalanceViewModel } from '../models/BalanceViewModel';
import type { BankView } from '../models/BankView';
import type { DetailTransactionViewModel } from '../models/DetailTransactionViewModel';
import type { EarnedData } from '../models/EarnedData';
import type { GetDepositUrlRequest } from '../models/GetDepositUrlRequest';
import type { MerchantPaymentViewModel } from '../models/MerchantPaymentViewModel';
import type { TransactionStatusView } from '../models/TransactionStatusView';
import type { TransactionTypeView } from '../models/TransactionTypeView';
import type { TransactionView } from '../models/TransactionView';
import type { TransferMoneyRequest } from '../models/TransferMoneyRequest';
import type { UpdateBalanceRequest } from '../models/UpdateBalanceRequest';
import type { UpdateTransactionRequest } from '../models/UpdateTransactionRequest';
import type { UploadPictureRequest } from '../models/UploadPictureRequest';
import type { USDTPriceViewModel } from '../models/USDTPriceViewModel';
import type { WithdrawBankcardRequest } from '../models/WithdrawBankcardRequest';
import type { WithdrawPayeerRequest } from '../models/WithdrawPayeerRequest';
import type { WithdrawSbpRequest } from '../models/WithdrawSbpRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountingService {
  /**
   * Get USDT to RUB price
   * @returns any Gets a Price usdt to rub
   * @throws ApiError
   */
  public static getUsdtPrice(): CancelablePromise<{
    status: boolean;
    data: USDTPriceViewModel;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/accounting/get-usdt-price',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets the balances of the current user
   * @returns any Returns the balances of the current user
   * @throws ApiError
   */
  public static getCurrentUserBalance(): CancelablePromise<{
    status: boolean;
    data: BalanceViewModel;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/accounting/balance',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets my earned amount.
   * @returns any Returns my earned amount.
   * @throws ApiError
   */
  public static getMyEarnedAmount(): CancelablePromise<{
    status: boolean;
    data: EarnedData;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/accounting/earned',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Uploads a picture of my income and sends it to the telegram chat.
   * @param formData
   * @returns void
   * @throws ApiError
   */
  public static uploadNyEarningImage(formData: UploadPictureRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/accounting/earned',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets a list of operations for the current user
   * @param offset Offset
   * @param limit Limit
   * @param status Transaction statuses (optional)
   * @param type Transaction types (optional)
   * @returns any Returns a list of operations for the current user
   * @throws ApiError
   */
  public static getTransactionList(
    offset?: number,
    limit: number = 20,
    status?: Array<'pending' | 'completed' | 'processing' | 'rejected' | 'cancelled'>,
    type?: Array<
      | 'deposit'
      | 'withdraw'
      | 'transfer_from'
      | 'transfer_to'
      | 'buy_desk_greenbox_1'
      | 'buy_desk_greenbox_2'
      | 'buy_desk_greenbox_3'
      | 'buy_desk_greenbox_4'
      | 'buy_desk_bluebox_1'
      | 'buy_desk_bluebox_2'
      | 'buy_desk_bluebox_3'
      | 'buy_desk_bluebox_4'
      | 'buy_desk_redbox_1'
      | 'buy_desk_redbox_2'
      | 'buy_desk_redbox_3'
      | 'buy_desk_redbox_4'
      | 'buy_clone_greenbox_1'
      | 'buy_clone_greenbox_2'
      | 'buy_clone_greenbox_3'
      | 'buy_clone_greenbox_4'
      | 'buy_clone_bluebox_1'
      | 'buy_clone_bluebox_2'
      | 'buy_clone_bluebox_3'
      | 'buy_clone_bluebox_4'
      | 'buy_clone_redbox_1'
      | 'buy_clone_redbox_2'
      | 'buy_clone_redbox_3'
      | 'buy_clone_redbox_4'
      | 'bonus_greenbox_1'
      | 'bonus_greenbox_2'
      | 'bonus_greenbox_3'
      | 'bonus_greenbox_4'
      | 'bonus_bluebox_1'
      | 'bonus_bluebox_2'
      | 'bonus_bluebox_3'
      | 'bonus_bluebox_4'
      | 'bonus_redbox_1'
      | 'bonus_redbox_2'
      | 'bonus_redbox_3'
      | 'bonus_redbox_4'
      | 'referral_bonus_greenbox_1'
      | 'referral_bonus_greenbox_2'
      | 'referral_bonus_greenbox_3'
      | 'referral_bonus_greenbox_4'
      | 'referral_bonus_bluebox_1'
      | 'referral_bonus_bluebox_2'
      | 'referral_bonus_bluebox_3'
      | 'referral_bonus_bluebox_4'
      | 'referral_bonus_redbox_1'
      | 'referral_bonus_redbox_2'
      | 'referral_bonus_redbox_3'
      | 'referral_bonus_redbox_4'
      | 'cumulative_bonus_greenbox_1'
      | 'cumulative_bonus_greenbox_2'
      | 'cumulative_bonus_greenbox_3'
      | 'cumulative_bonus_greenbox_4'
      | 'cumulative_bonus_bluebox_1'
      | 'cumulative_bonus_bluebox_2'
      | 'cumulative_bonus_bluebox_3'
      | 'cumulative_bonus_bluebox_4'
      | 'cumulative_bonus_redbox_1'
      | 'cumulative_bonus_redbox_2'
      | 'cumulative_bonus_redbox_3'
      | 'cumulative_bonus_redbox_4'
      | 'buy_magicbox_ticket'
      | 'refund_magicbox_ticket'
      | 'buy_pre_order_boombox_bronze_1'
      | 'buy_pre_order_boombox_bronze_2'
      | 'buy_pre_order_boombox_bronze_3'
      | 'buy_pre_order_boombox_bronze_4'
      | 'buy_pre_order_boombox_bronze_5'
      | 'buy_pre_order_boombox_bronze_6'
      | 'buy_pre_order_boombox_silver_1'
      | 'buy_pre_order_boombox_silver_2'
      | 'buy_pre_order_boombox_silver_3'
      | 'buy_pre_order_boombox_silver_4'
      | 'buy_pre_order_boombox_silver_5'
      | 'buy_pre_order_boombox_silver_6'
      | 'buy_pre_order_boombox_gold_1'
      | 'buy_pre_order_boombox_gold_2'
      | 'buy_pre_order_boombox_gold_3'
      | 'buy_pre_order_boombox_gold_4'
      | 'buy_pre_order_boombox_gold_5'
      | 'buy_pre_order_boombox_gold_6'
      | 'buy_desk_boombox_bronze_1'
      | 'buy_desk_boombox_bronze_2'
      | 'buy_desk_boombox_bronze_3'
      | 'buy_desk_boombox_bronze_4'
      | 'buy_desk_boombox_bronze_5'
      | 'buy_desk_boombox_bronze_6'
      | 'buy_desk_boombox_silver_1'
      | 'buy_desk_boombox_silver_2'
      | 'buy_desk_boombox_silver_3'
      | 'buy_desk_boombox_silver_4'
      | 'buy_desk_boombox_silver_5'
      | 'buy_desk_boombox_silver_6'
      | 'buy_desk_boombox_gold_1'
      | 'buy_desk_boombox_gold_2'
      | 'buy_desk_boombox_gold_3'
      | 'buy_desk_boombox_gold_4'
      | 'buy_desk_boombox_gold_5'
      | 'buy_desk_boombox_gold_6'
      | 'buy_clone_boombox_bronze_1'
      | 'buy_clone_boombox_bronze_2'
      | 'buy_clone_boombox_bronze_3'
      | 'buy_clone_boombox_bronze_4'
      | 'buy_clone_boombox_bronze_5'
      | 'buy_clone_boombox_bronze_6'
      | 'buy_clone_boombox_silver_1'
      | 'buy_clone_boombox_silver_2'
      | 'buy_clone_boombox_silver_3'
      | 'buy_clone_boombox_silver_4'
      | 'buy_clone_boombox_silver_5'
      | 'buy_clone_boombox_silver_6'
      | 'buy_clone_boombox_gold_1'
      | 'buy_clone_boombox_gold_2'
      | 'buy_clone_boombox_gold_3'
      | 'buy_clone_boombox_gold_4'
      | 'buy_clone_boombox_gold_5'
      | 'buy_clone_boombox_gold_6'
      | 'bonus_bronze_1'
      | 'bonus_bronze_2'
      | 'bonus_bronze_3'
      | 'bonus_bronze_4'
      | 'bonus_bronze_5'
      | 'bonus_bronze_6'
      | 'bonus_silver_1'
      | 'bonus_silver_2'
      | 'bonus_silver_3'
      | 'bonus_silver_4'
      | 'bonus_silver_5'
      | 'bonus_silver_6'
      | 'bonus_gold_1'
      | 'bonus_gold_2'
      | 'bonus_gold_3'
      | 'bonus_gold_4'
      | 'bonus_gold_5'
      | 'bonus_gold_6'
      | 'cumulative_bonus_bronze_1'
      | 'cumulative_bonus_bronze_2'
      | 'cumulative_bonus_bronze_3'
      | 'cumulative_bonus_bronze_4'
      | 'cumulative_bonus_bronze_5'
      | 'cumulative_bonus_bronze_6'
      | 'cumulative_bonus_silver_1'
      | 'cumulative_bonus_silver_2'
      | 'cumulative_bonus_silver_3'
      | 'cumulative_bonus_silver_4'
      | 'cumulative_bonus_silver_5'
      | 'cumulative_bonus_silver_6'
      | 'cumulative_bonus_gold_1'
      | 'cumulative_bonus_gold_2'
      | 'cumulative_bonus_gold_3'
      | 'cumulative_bonus_gold_4'
      | 'cumulative_bonus_gold_5'
      | 'cumulative_bonus_gold_6'
      | 'referral_bonus_bronze_1'
      | 'referral_bonus_bronze_2'
      | 'referral_bonus_bronze_3'
      | 'referral_bonus_bronze_4'
      | 'referral_bonus_bronze_5'
      | 'referral_bonus_bronze_6'
      | 'referral_bonus_silver_1'
      | 'referral_bonus_silver_2'
      | 'referral_bonus_silver_3'
      | 'referral_bonus_silver_4'
      | 'referral_bonus_silver_5'
      | 'referral_bonus_silver_6'
      | 'referral_bonus_gold_1'
      | 'referral_bonus_gold_2'
      | 'referral_bonus_gold_3'
      | 'referral_bonus_gold_4'
      | 'referral_bonus_gold_5'
      | 'referral_bonus_gold_6'
      | 'buy_pre_order_top_box_emerald_1'
      | 'buy_pre_order_top_box_emerald_2'
      | 'buy_pre_order_top_box_emerald_3'
      | 'buy_pre_order_top_box_ruby_1'
      | 'buy_pre_order_top_box_ruby_2'
      | 'buy_pre_order_top_box_ruby_3'
      | 'buy_pre_order_top_box_diamond_1'
      | 'buy_pre_order_top_box_diamond_2'
      | 'buy_pre_order_top_box_diamond_3'
      | 'buy_desk_top_box_emerald_1'
      | 'buy_desk_top_box_emerald_2'
      | 'buy_desk_top_box_emerald_3'
      | 'buy_desk_top_box_ruby_1'
      | 'buy_desk_top_box_ruby_2'
      | 'buy_desk_top_box_ruby_3'
      | 'buy_desk_top_box_diamond_1'
      | 'buy_desk_top_box_diamond_2'
      | 'buy_desk_top_box_diamond_3'
      | 'buy_clone_top_box_emerald_1'
      | 'buy_clone_top_box_emerald_2'
      | 'buy_clone_top_box_emerald_3'
      | 'buy_clone_top_box_ruby_1'
      | 'buy_clone_top_box_ruby_2'
      | 'buy_clone_top_box_ruby_3'
      | 'buy_clone_top_box_diamond_1'
      | 'buy_clone_top_box_diamond_2'
      | 'buy_clone_top_box_diamond_3'
      | 'bonus_top_box_emerald_1'
      | 'bonus_top_box_emerald_2'
      | 'bonus_top_box_emerald_3'
      | 'bonus_top_box_ruby_1'
      | 'bonus_top_box_ruby_2'
      | 'bonus_top_box_ruby_3'
      | 'bonus_top_box_diamond_1'
      | 'bonus_top_box_diamond_2'
      | 'bonus_top_box_diamond_3'
      | 'cumulative_bonus_top_box_emerald_1'
      | 'cumulative_bonus_top_box_emerald_2'
      | 'cumulative_bonus_top_box_emerald_3'
      | 'cumulative_bonus_top_box_ruby_1'
      | 'cumulative_bonus_top_box_ruby_2'
      | 'cumulative_bonus_top_box_ruby_3'
      | 'cumulative_bonus_top_box_diamond_1'
      | 'cumulative_bonus_top_box_diamond_2'
      | 'cumulative_bonus_top_box_diamond_3'
      | 'referral_bonus_top_box_emerald_line_1'
      | 'referral_bonus_top_box_emerald_line_2'
      | 'referral_bonus_top_box_emerald_line_3'
      | 'referral_bonus_top_box_emerald_line_4'
      | 'referral_bonus_top_box_emerald_line_5'
      | 'referral_bonus_top_box_ruby_line_1'
      | 'referral_bonus_top_box_ruby_line_2'
      | 'referral_bonus_top_box_ruby_line_3'
      | 'referral_bonus_top_box_ruby_line_4'
      | 'referral_bonus_top_box_ruby_line_5'
      | 'referral_bonus_top_box_diamond_line_1'
      | 'referral_bonus_top_box_diamond_line_2'
      | 'referral_bonus_top_box_diamond_line_3'
      | 'referral_bonus_top_box_diamond_line_4'
      | 'referral_bonus_top_box_diamond_line_5'
      | 'buy_pre_order_tictac_yellow_1'
      | 'buy_pre_order_tictac_yellow_2'
      | 'buy_pre_order_tictac_yellow_3'
      | 'buy_pre_order_tictac_yellow_4'
      | 'buy_pre_order_tictac_yellow_5'
      | 'buy_pre_order_tictac_yellow_6'
      | 'buy_pre_order_tictac_green_1'
      | 'buy_pre_order_tictac_green_2'
      | 'buy_pre_order_tictac_green_3'
      | 'buy_pre_order_tictac_green_4'
      | 'buy_pre_order_tictac_green_5'
      | 'buy_pre_order_tictac_green_6'
      | 'buy_pre_order_tictac_orange_1'
      | 'buy_pre_order_tictac_orange_2'
      | 'buy_pre_order_tictac_orange_3'
      | 'buy_pre_order_tictac_orange_4'
      | 'buy_pre_order_tictac_orange_5'
      | 'buy_pre_order_tictac_orange_6'
      | 'buy_desk_tictac_yellow_1'
      | 'buy_desk_tictac_yellow_2'
      | 'buy_desk_tictac_yellow_3'
      | 'buy_desk_tictac_yellow_4'
      | 'buy_desk_tictac_yellow_5'
      | 'buy_desk_tictac_yellow_6'
      | 'buy_clone_tictac_yellow_1'
      | 'buy_clone_tictac_yellow_2'
      | 'buy_clone_tictac_yellow_3'
      | 'buy_clone_tictac_yellow_4'
      | 'buy_clone_tictac_yellow_5'
      | 'buy_clone_tictac_yellow_6'
      | 'bonus_yellow_1'
      | 'bonus_yellow_2'
      | 'bonus_yellow_3'
      | 'bonus_yellow_4'
      | 'bonus_yellow_5'
      | 'bonus_yellow_6'
      | 'referral_bonus_yellow_1'
      | 'referral_bonus_yellow_2'
      | 'referral_bonus_yellow_3'
      | 'referral_bonus_yellow_4'
      | 'referral_bonus_yellow_5'
      | 'referral_bonus_yellow_6'
      | 'cumulative_bonus_yellow_1'
      | 'cumulative_bonus_yellow_2'
      | 'cumulative_bonus_yellow_3'
      | 'cumulative_bonus_yellow_4'
      | 'cumulative_bonus_yellow_5'
      | 'cumulative_bonus_yellow_6'
      | 'buy_desk_tictac_green_1'
      | 'buy_desk_tictac_green_2'
      | 'buy_desk_tictac_green_3'
      | 'buy_desk_tictac_green_4'
      | 'buy_desk_tictac_green_5'
      | 'buy_desk_tictac_green_6'
      | 'buy_clone_tictac_green_1'
      | 'buy_clone_tictac_green_2'
      | 'buy_clone_tictac_green_3'
      | 'buy_clone_tictac_green_4'
      | 'buy_clone_tictac_green_5'
      | 'buy_clone_tictac_green_6'
      | 'bonus_green_1'
      | 'bonus_green_2'
      | 'bonus_green_3'
      | 'bonus_green_4'
      | 'bonus_green_5'
      | 'bonus_green_6'
      | 'referral_bonus_green_1'
      | 'referral_bonus_green_2'
      | 'referral_bonus_green_3'
      | 'referral_bonus_green_4'
      | 'referral_bonus_green_5'
      | 'referral_bonus_green_6'
      | 'cumulative_bonus_green_1'
      | 'cumulative_bonus_green_2'
      | 'cumulative_bonus_green_3'
      | 'cumulative_bonus_green_4'
      | 'cumulative_bonus_green_5'
      | 'cumulative_bonus_green_6'
      | 'buy_desk_tictac_orange_1'
      | 'buy_desk_tictac_orange_2'
      | 'buy_desk_tictac_orange_3'
      | 'buy_desk_tictac_orange_4'
      | 'buy_desk_tictac_orange_5'
      | 'buy_desk_tictac_orange_6'
      | 'buy_clone_tictac_orange_1'
      | 'buy_clone_tictac_orange_2'
      | 'buy_clone_tictac_orange_3'
      | 'buy_clone_tictac_orange_4'
      | 'buy_clone_tictac_orange_5'
      | 'buy_clone_tictac_orange_6'
      | 'bonus_orange_1'
      | 'bonus_orange_2'
      | 'bonus_orange_3'
      | 'bonus_orange_4'
      | 'bonus_orange_5'
      | 'bonus_orange_6'
      | 'referral_bonus_orange_1'
      | 'referral_bonus_orange_2'
      | 'referral_bonus_orange_3'
      | 'referral_bonus_orange_4'
      | 'referral_bonus_orange_5'
      | 'referral_bonus_orange_6'
      | 'cumulative_bonus_orange_1'
      | 'cumulative_bonus_orange_2'
      | 'cumulative_bonus_orange_3'
      | 'cumulative_bonus_orange_4'
      | 'cumulative_bonus_orange_5'
      | 'cumulative_bonus_orange_6'
      | 'buy_pre_order_klondaik_red_gold_1'
      | 'buy_pre_order_klondaik_red_gold_2'
      | 'buy_pre_order_klondaik_red_gold_3'
      | 'buy_pre_order_klondaik_red_gold_4'
      | 'buy_pre_order_klondaik_red_gold_5'
      | 'buy_pre_order_klondaik_red_gold_6'
      | 'buy_pre_order_klondaik_yellow_gold_1'
      | 'buy_pre_order_klondaik_yellow_gold_2'
      | 'buy_pre_order_klondaik_yellow_gold_3'
      | 'buy_pre_order_klondaik_yellow_gold_4'
      | 'buy_pre_order_klondaik_yellow_gold_5'
      | 'buy_pre_order_klondaik_yellow_gold_6'
      | 'buy_pre_order_klondaik_white_gold_1'
      | 'buy_pre_order_klondaik_white_gold_2'
      | 'buy_pre_order_klondaik_white_gold_3'
      | 'buy_pre_order_klondaik_white_gold_4'
      | 'buy_pre_order_klondaik_white_gold_5'
      | 'buy_pre_order_klondaik_white_gold_6'
      | 'buy_desk_klondaik_red_gold_1'
      | 'buy_desk_klondaik_red_gold_2'
      | 'buy_desk_klondaik_red_gold_3'
      | 'buy_desk_klondaik_red_gold_4'
      | 'buy_desk_klondaik_red_gold_5'
      | 'buy_desk_klondaik_red_gold_6'
      | 'buy_clone_klondaik_red_gold_1'
      | 'buy_clone_klondaik_red_gold_2'
      | 'buy_clone_klondaik_red_gold_3'
      | 'buy_clone_klondaik_red_gold_4'
      | 'buy_clone_klondaik_red_gold_5'
      | 'buy_clone_klondaik_red_gold_6'
      | 'bonus_klondaik_red_gold_1'
      | 'bonus_klondaik_red_gold_2'
      | 'bonus_klondaik_red_gold_3'
      | 'bonus_klondaik_red_gold_4'
      | 'bonus_klondaik_red_gold_5'
      | 'bonus_klondaik_red_gold_6'
      | 'referral_bonus_klondaik_red_gold_1'
      | 'referral_bonus_klondaik_red_gold_2'
      | 'referral_bonus_klondaik_red_gold_3'
      | 'referral_bonus_klondaik_red_gold_4'
      | 'referral_bonus_klondaik_red_gold_5'
      | 'referral_bonus_klondaik_red_gold_6'
      | 'cumulative_bonus_klondaik_red_gold_1'
      | 'cumulative_bonus_klondaik_red_gold_2'
      | 'cumulative_bonus_klondaik_red_gold_3'
      | 'cumulative_bonus_klondaik_red_gold_4'
      | 'cumulative_bonus_klondaik_red_gold_5'
      | 'cumulative_bonus_klondaik_red_gold_6'
      | 'buy_desk_klondaik_yellow_gold_1'
      | 'buy_desk_klondaik_yellow_gold_2'
      | 'buy_desk_klondaik_yellow_gold_3'
      | 'buy_desk_klondaik_yellow_gold_4'
      | 'buy_desk_klondaik_yellow_gold_5'
      | 'buy_desk_klondaik_yellow_gold_6'
      | 'buy_clone_klondaik_yellow_gold_1'
      | 'buy_clone_klondaik_yellow_gold_2'
      | 'buy_clone_klondaik_yellow_gold_3'
      | 'buy_clone_klondaik_yellow_gold_4'
      | 'buy_clone_klondaik_yellow_gold_5'
      | 'buy_clone_klondaik_yellow_gold_6'
      | 'bonus_klondaik_yellow_gold_1'
      | 'bonus_klondaik_yellow_gold_2'
      | 'bonus_klondaik_yellow_gold_3'
      | 'bonus_klondaik_yellow_gold_4'
      | 'bonus_klondaik_yellow_gold_5'
      | 'bonus_klondaik_yellow_gold_6'
      | 'referral_bonus_klondaik_yellow_gold_1'
      | 'referral_bonus_klondaik_yellow_gold_2'
      | 'referral_bonus_klondaik_yellow_gold_3'
      | 'referral_bonus_klondaik_yellow_gold_4'
      | 'referral_bonus_klondaik_yellow_gold_5'
      | 'referral_bonus_klondaik_yellow_gold_6'
      | 'cumulative_bonus_klondaik_yellow_gold_1'
      | 'cumulative_bonus_klondaik_yellow_gold_2'
      | 'cumulative_bonus_klondaik_yellow_gold_3'
      | 'cumulative_bonus_klondaik_yellow_gold_4'
      | 'cumulative_bonus_klondaik_yellow_gold_5'
      | 'cumulative_bonus_klondaik_yellow_gold_6'
      | 'buy_desk_klondaik_white_gold_1'
      | 'buy_desk_klondaik_white_gold_2'
      | 'buy_desk_klondaik_white_gold_3'
      | 'buy_desk_klondaik_white_gold_4'
      | 'buy_desk_klondaik_white_gold_5'
      | 'buy_desk_klondaik_white_gold_6'
      | 'buy_clone_klondaik_white_gold_1'
      | 'buy_clone_klondaik_white_gold_2'
      | 'buy_clone_klondaik_white_gold_3'
      | 'buy_clone_klondaik_white_gold_4'
      | 'buy_clone_klondaik_white_gold_5'
      | 'buy_clone_klondaik_white_gold_6'
      | 'bonus_klondaik_white_gold_1'
      | 'bonus_klondaik_white_gold_2'
      | 'bonus_klondaik_white_gold_3'
      | 'bonus_klondaik_white_gold_4'
      | 'bonus_klondaik_white_gold_5'
      | 'bonus_klondaik_white_gold_6'
      | 'referral_bonus_klondaik_white_gold_1'
      | 'referral_bonus_klondaik_white_gold_2'
      | 'referral_bonus_klondaik_white_gold_3'
      | 'referral_bonus_klondaik_white_gold_4'
      | 'referral_bonus_klondaik_white_gold_5'
      | 'referral_bonus_klondaik_white_gold_6'
      | 'cumulative_bonus_klondaik_white_gold_1'
      | 'cumulative_bonus_klondaik_white_gold_2'
      | 'cumulative_bonus_klondaik_white_gold_3'
      | 'cumulative_bonus_klondaik_white_gold_4'
      | 'cumulative_bonus_klondaik_white_gold_5'
      | 'cumulative_bonus_klondaik_white_gold_6'
    >
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<TransactionView>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/accounting/transaction/list',
      query: {
        offset: offset,
        limit: limit,
        'status[]': status,
        'type[]': type,
      },
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets a list of operation types
   * @returns any Returns a list of operation types
   * @throws ApiError
   */
  public static getTransactionTypes(): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<TransactionTypeView>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/accounting/transaction/types',
    });
  }

  /**
   * Gets a list of operation statuses
   * @returns any Returns a list of operation statuses
   * @throws ApiError
   */
  public static getTransactionStatuses(): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<TransactionStatusView>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/accounting/transaction/statuses',
    });
  }

  /**
   * Transfers money to another user's account
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static transferMoney(requestBody: TransferMoneyRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/accounting/transfer',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Deposit balance via freekassa
   * @param requestBody
   * @returns any Gets a link to deposit the balance, through freekassa.
   * @throws ApiError
   */
  public static depositFreekassa(requestBody: GetDepositUrlRequest): CancelablePromise<{
    status: boolean;
    data: MerchantPaymentViewModel;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/accounting/deposit/freekassa',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Deposit balance via payeer
   * @param requestBody
   * @returns any Gets a link to deposit the balance, through payeer.
   * @throws ApiError
   */
  public static depositPayeer(requestBody: GetDepositUrlRequest): CancelablePromise<{
    status: boolean;
    data: MerchantPaymentViewModel;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/accounting/deposit/payeer',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Requests withdrawal of funds via Payeer.
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static withdrawPayeer(requestBody: WithdrawPayeerRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/accounting/withdraw/payeer',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Requests withdrawal of funds via Payeer USDT.
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static withdrawPayeerUsdt(requestBody: WithdrawPayeerRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/accounting/withdraw/payeer/usdt',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Requests withdrawal of funds through Freekassa to a bank card.
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static withdrawFreekassaBankcard(requestBody: WithdrawBankcardRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/accounting/withdraw/freekassa/bankcard',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Return list of banks for freekassa
   * @returns any Returns a list of banks
   * @throws ApiError
   */
  public static freekassaSbpBanks(): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<BankView>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/accounting/withdraw/freekassa/bank_list',
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Requests withdrawal of funds via Freekassa to SBP account.
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static withdrawFreekassaSbp(requestBody: WithdrawSbpRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/accounting/withdraw/freekassa/sbp',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
      },
    });
  }

  /**
   * Cancels a pending withdrawal request.
   * Only a request in the "pending" status can be canceled, otherwise there will be an error
   * @param transactionId Transaction ID
   * @returns void
   * @throws ApiError
   */
  public static cancelWithdraw(transactionId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/accounting/withdraw/{transactionId}/cancel',
      path: {
        transactionId: transactionId,
      },
      errors: {
        403: `Access Denied`,
        404: `Transaction not found or owned by another user`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Updates the user's balance
   * @param userId ID of the user whose balance is being updated
   * @param requestBody New balance values
   * @returns void
   * @throws ApiError
   */
  public static updateBalance(userId: string, requestBody: UpdateBalanceRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/accounting/{userId}/balance',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Approves a user transaction for withdrawal
   * After the transaction is approved, the transfer process starts. If an error occurs during the transfer, the transaction is marked as pending.
   * @param transactionId Transaction ID
   * @returns void
   * @throws ApiError
   */
  public static adminWithdrawTransactionApprove(transactionId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/accounting/withdraw/{transactionId}/approve',
      path: {
        transactionId: transactionId,
      },
      errors: {
        403: `Access Denied`,
        404: `Transaction not found.`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Rejects a user transaction for withdrawal
   * After the transaction is rejected, the holden money is automatically returned to the balance.
   * @param transactionId Transaction ID
   * @returns void
   * @throws ApiError
   */
  public static adminWithdrawTransactionReject(transactionId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/accounting/withdraw/{transactionId}/reject',
      path: {
        transactionId: transactionId,
      },
      errors: {
        403: `Access Denied`,
        404: `Transaction not found.`,
        422: `A property is missing in the request, or an error has occurred in the business logic.`,
      },
    });
  }

  /**
   * Gets a detailed list of transactions for all users.
   * @param offset Offset
   * @param limit Limit
   * @param username Filter by username
   * @param status Transaction statuses (optional)
   * @param type Transaction types (optional)
   * @param direction Transaction direction (optional)
   * @param createdAtStart Filter by transaction date after
   * @param createdAtEnd Filter by transaction date before
   * @param sort Sort results
   * @param order Order by results
   * @returns any Returns a detailed list of transactions for all users.
   * @throws ApiError
   */
  public static getDetailUserTransactionList(
    offset?: number,
    limit: number = 20,
    username?: string,
    status?: Array<'pending' | 'completed' | 'processing' | 'rejected' | 'cancelled'>,
    type?: Array<
      | 'deposit'
      | 'withdraw'
      | 'transfer_from'
      | 'transfer_to'
      | 'buy_desk_greenbox_1'
      | 'buy_desk_greenbox_2'
      | 'buy_desk_greenbox_3'
      | 'buy_desk_greenbox_4'
      | 'buy_desk_bluebox_1'
      | 'buy_desk_bluebox_2'
      | 'buy_desk_bluebox_3'
      | 'buy_desk_bluebox_4'
      | 'buy_desk_redbox_1'
      | 'buy_desk_redbox_2'
      | 'buy_desk_redbox_3'
      | 'buy_desk_redbox_4'
      | 'buy_clone_greenbox_1'
      | 'buy_clone_greenbox_2'
      | 'buy_clone_greenbox_3'
      | 'buy_clone_greenbox_4'
      | 'buy_clone_bluebox_1'
      | 'buy_clone_bluebox_2'
      | 'buy_clone_bluebox_3'
      | 'buy_clone_bluebox_4'
      | 'buy_clone_redbox_1'
      | 'buy_clone_redbox_2'
      | 'buy_clone_redbox_3'
      | 'buy_clone_redbox_4'
      | 'bonus_greenbox_1'
      | 'bonus_greenbox_2'
      | 'bonus_greenbox_3'
      | 'bonus_greenbox_4'
      | 'bonus_bluebox_1'
      | 'bonus_bluebox_2'
      | 'bonus_bluebox_3'
      | 'bonus_bluebox_4'
      | 'bonus_redbox_1'
      | 'bonus_redbox_2'
      | 'bonus_redbox_3'
      | 'bonus_redbox_4'
      | 'referral_bonus_greenbox_1'
      | 'referral_bonus_greenbox_2'
      | 'referral_bonus_greenbox_3'
      | 'referral_bonus_greenbox_4'
      | 'referral_bonus_bluebox_1'
      | 'referral_bonus_bluebox_2'
      | 'referral_bonus_bluebox_3'
      | 'referral_bonus_bluebox_4'
      | 'referral_bonus_redbox_1'
      | 'referral_bonus_redbox_2'
      | 'referral_bonus_redbox_3'
      | 'referral_bonus_redbox_4'
      | 'cumulative_bonus_greenbox_1'
      | 'cumulative_bonus_greenbox_2'
      | 'cumulative_bonus_greenbox_3'
      | 'cumulative_bonus_greenbox_4'
      | 'cumulative_bonus_bluebox_1'
      | 'cumulative_bonus_bluebox_2'
      | 'cumulative_bonus_bluebox_3'
      | 'cumulative_bonus_bluebox_4'
      | 'cumulative_bonus_redbox_1'
      | 'cumulative_bonus_redbox_2'
      | 'cumulative_bonus_redbox_3'
      | 'cumulative_bonus_redbox_4'
      | 'buy_magicbox_ticket'
      | 'refund_magicbox_ticket'
      | 'buy_pre_order_boombox_bronze_1'
      | 'buy_pre_order_boombox_bronze_2'
      | 'buy_pre_order_boombox_bronze_3'
      | 'buy_pre_order_boombox_bronze_4'
      | 'buy_pre_order_boombox_bronze_5'
      | 'buy_pre_order_boombox_bronze_6'
      | 'buy_pre_order_boombox_silver_1'
      | 'buy_pre_order_boombox_silver_2'
      | 'buy_pre_order_boombox_silver_3'
      | 'buy_pre_order_boombox_silver_4'
      | 'buy_pre_order_boombox_silver_5'
      | 'buy_pre_order_boombox_silver_6'
      | 'buy_pre_order_boombox_gold_1'
      | 'buy_pre_order_boombox_gold_2'
      | 'buy_pre_order_boombox_gold_3'
      | 'buy_pre_order_boombox_gold_4'
      | 'buy_pre_order_boombox_gold_5'
      | 'buy_pre_order_boombox_gold_6'
      | 'buy_desk_boombox_bronze_1'
      | 'buy_desk_boombox_bronze_2'
      | 'buy_desk_boombox_bronze_3'
      | 'buy_desk_boombox_bronze_4'
      | 'buy_desk_boombox_bronze_5'
      | 'buy_desk_boombox_bronze_6'
      | 'buy_desk_boombox_silver_1'
      | 'buy_desk_boombox_silver_2'
      | 'buy_desk_boombox_silver_3'
      | 'buy_desk_boombox_silver_4'
      | 'buy_desk_boombox_silver_5'
      | 'buy_desk_boombox_silver_6'
      | 'buy_desk_boombox_gold_1'
      | 'buy_desk_boombox_gold_2'
      | 'buy_desk_boombox_gold_3'
      | 'buy_desk_boombox_gold_4'
      | 'buy_desk_boombox_gold_5'
      | 'buy_desk_boombox_gold_6'
      | 'buy_clone_boombox_bronze_1'
      | 'buy_clone_boombox_bronze_2'
      | 'buy_clone_boombox_bronze_3'
      | 'buy_clone_boombox_bronze_4'
      | 'buy_clone_boombox_bronze_5'
      | 'buy_clone_boombox_bronze_6'
      | 'buy_clone_boombox_silver_1'
      | 'buy_clone_boombox_silver_2'
      | 'buy_clone_boombox_silver_3'
      | 'buy_clone_boombox_silver_4'
      | 'buy_clone_boombox_silver_5'
      | 'buy_clone_boombox_silver_6'
      | 'buy_clone_boombox_gold_1'
      | 'buy_clone_boombox_gold_2'
      | 'buy_clone_boombox_gold_3'
      | 'buy_clone_boombox_gold_4'
      | 'buy_clone_boombox_gold_5'
      | 'buy_clone_boombox_gold_6'
      | 'bonus_bronze_1'
      | 'bonus_bronze_2'
      | 'bonus_bronze_3'
      | 'bonus_bronze_4'
      | 'bonus_bronze_5'
      | 'bonus_bronze_6'
      | 'bonus_silver_1'
      | 'bonus_silver_2'
      | 'bonus_silver_3'
      | 'bonus_silver_4'
      | 'bonus_silver_5'
      | 'bonus_silver_6'
      | 'bonus_gold_1'
      | 'bonus_gold_2'
      | 'bonus_gold_3'
      | 'bonus_gold_4'
      | 'bonus_gold_5'
      | 'bonus_gold_6'
      | 'cumulative_bonus_bronze_1'
      | 'cumulative_bonus_bronze_2'
      | 'cumulative_bonus_bronze_3'
      | 'cumulative_bonus_bronze_4'
      | 'cumulative_bonus_bronze_5'
      | 'cumulative_bonus_bronze_6'
      | 'cumulative_bonus_silver_1'
      | 'cumulative_bonus_silver_2'
      | 'cumulative_bonus_silver_3'
      | 'cumulative_bonus_silver_4'
      | 'cumulative_bonus_silver_5'
      | 'cumulative_bonus_silver_6'
      | 'cumulative_bonus_gold_1'
      | 'cumulative_bonus_gold_2'
      | 'cumulative_bonus_gold_3'
      | 'cumulative_bonus_gold_4'
      | 'cumulative_bonus_gold_5'
      | 'cumulative_bonus_gold_6'
      | 'referral_bonus_bronze_1'
      | 'referral_bonus_bronze_2'
      | 'referral_bonus_bronze_3'
      | 'referral_bonus_bronze_4'
      | 'referral_bonus_bronze_5'
      | 'referral_bonus_bronze_6'
      | 'referral_bonus_silver_1'
      | 'referral_bonus_silver_2'
      | 'referral_bonus_silver_3'
      | 'referral_bonus_silver_4'
      | 'referral_bonus_silver_5'
      | 'referral_bonus_silver_6'
      | 'referral_bonus_gold_1'
      | 'referral_bonus_gold_2'
      | 'referral_bonus_gold_3'
      | 'referral_bonus_gold_4'
      | 'referral_bonus_gold_5'
      | 'referral_bonus_gold_6'
      | 'buy_pre_order_top_box_emerald_1'
      | 'buy_pre_order_top_box_emerald_2'
      | 'buy_pre_order_top_box_emerald_3'
      | 'buy_pre_order_top_box_ruby_1'
      | 'buy_pre_order_top_box_ruby_2'
      | 'buy_pre_order_top_box_ruby_3'
      | 'buy_pre_order_top_box_diamond_1'
      | 'buy_pre_order_top_box_diamond_2'
      | 'buy_pre_order_top_box_diamond_3'
      | 'buy_desk_top_box_emerald_1'
      | 'buy_desk_top_box_emerald_2'
      | 'buy_desk_top_box_emerald_3'
      | 'buy_desk_top_box_ruby_1'
      | 'buy_desk_top_box_ruby_2'
      | 'buy_desk_top_box_ruby_3'
      | 'buy_desk_top_box_diamond_1'
      | 'buy_desk_top_box_diamond_2'
      | 'buy_desk_top_box_diamond_3'
      | 'buy_clone_top_box_emerald_1'
      | 'buy_clone_top_box_emerald_2'
      | 'buy_clone_top_box_emerald_3'
      | 'buy_clone_top_box_ruby_1'
      | 'buy_clone_top_box_ruby_2'
      | 'buy_clone_top_box_ruby_3'
      | 'buy_clone_top_box_diamond_1'
      | 'buy_clone_top_box_diamond_2'
      | 'buy_clone_top_box_diamond_3'
      | 'bonus_top_box_emerald_1'
      | 'bonus_top_box_emerald_2'
      | 'bonus_top_box_emerald_3'
      | 'bonus_top_box_ruby_1'
      | 'bonus_top_box_ruby_2'
      | 'bonus_top_box_ruby_3'
      | 'bonus_top_box_diamond_1'
      | 'bonus_top_box_diamond_2'
      | 'bonus_top_box_diamond_3'
      | 'cumulative_bonus_top_box_emerald_1'
      | 'cumulative_bonus_top_box_emerald_2'
      | 'cumulative_bonus_top_box_emerald_3'
      | 'cumulative_bonus_top_box_ruby_1'
      | 'cumulative_bonus_top_box_ruby_2'
      | 'cumulative_bonus_top_box_ruby_3'
      | 'cumulative_bonus_top_box_diamond_1'
      | 'cumulative_bonus_top_box_diamond_2'
      | 'cumulative_bonus_top_box_diamond_3'
      | 'referral_bonus_top_box_emerald_line_1'
      | 'referral_bonus_top_box_emerald_line_2'
      | 'referral_bonus_top_box_emerald_line_3'
      | 'referral_bonus_top_box_emerald_line_4'
      | 'referral_bonus_top_box_emerald_line_5'
      | 'referral_bonus_top_box_ruby_line_1'
      | 'referral_bonus_top_box_ruby_line_2'
      | 'referral_bonus_top_box_ruby_line_3'
      | 'referral_bonus_top_box_ruby_line_4'
      | 'referral_bonus_top_box_ruby_line_5'
      | 'referral_bonus_top_box_diamond_line_1'
      | 'referral_bonus_top_box_diamond_line_2'
      | 'referral_bonus_top_box_diamond_line_3'
      | 'referral_bonus_top_box_diamond_line_4'
      | 'referral_bonus_top_box_diamond_line_5'
      | 'buy_pre_order_tictac_yellow_1'
      | 'buy_pre_order_tictac_yellow_2'
      | 'buy_pre_order_tictac_yellow_3'
      | 'buy_pre_order_tictac_yellow_4'
      | 'buy_pre_order_tictac_yellow_5'
      | 'buy_pre_order_tictac_yellow_6'
      | 'buy_pre_order_tictac_green_1'
      | 'buy_pre_order_tictac_green_2'
      | 'buy_pre_order_tictac_green_3'
      | 'buy_pre_order_tictac_green_4'
      | 'buy_pre_order_tictac_green_5'
      | 'buy_pre_order_tictac_green_6'
      | 'buy_pre_order_tictac_orange_1'
      | 'buy_pre_order_tictac_orange_2'
      | 'buy_pre_order_tictac_orange_3'
      | 'buy_pre_order_tictac_orange_4'
      | 'buy_pre_order_tictac_orange_5'
      | 'buy_pre_order_tictac_orange_6'
      | 'buy_desk_tictac_yellow_1'
      | 'buy_desk_tictac_yellow_2'
      | 'buy_desk_tictac_yellow_3'
      | 'buy_desk_tictac_yellow_4'
      | 'buy_desk_tictac_yellow_5'
      | 'buy_desk_tictac_yellow_6'
      | 'buy_clone_tictac_yellow_1'
      | 'buy_clone_tictac_yellow_2'
      | 'buy_clone_tictac_yellow_3'
      | 'buy_clone_tictac_yellow_4'
      | 'buy_clone_tictac_yellow_5'
      | 'buy_clone_tictac_yellow_6'
      | 'bonus_yellow_1'
      | 'bonus_yellow_2'
      | 'bonus_yellow_3'
      | 'bonus_yellow_4'
      | 'bonus_yellow_5'
      | 'bonus_yellow_6'
      | 'referral_bonus_yellow_1'
      | 'referral_bonus_yellow_2'
      | 'referral_bonus_yellow_3'
      | 'referral_bonus_yellow_4'
      | 'referral_bonus_yellow_5'
      | 'referral_bonus_yellow_6'
      | 'cumulative_bonus_yellow_1'
      | 'cumulative_bonus_yellow_2'
      | 'cumulative_bonus_yellow_3'
      | 'cumulative_bonus_yellow_4'
      | 'cumulative_bonus_yellow_5'
      | 'cumulative_bonus_yellow_6'
      | 'buy_desk_tictac_green_1'
      | 'buy_desk_tictac_green_2'
      | 'buy_desk_tictac_green_3'
      | 'buy_desk_tictac_green_4'
      | 'buy_desk_tictac_green_5'
      | 'buy_desk_tictac_green_6'
      | 'buy_clone_tictac_green_1'
      | 'buy_clone_tictac_green_2'
      | 'buy_clone_tictac_green_3'
      | 'buy_clone_tictac_green_4'
      | 'buy_clone_tictac_green_5'
      | 'buy_clone_tictac_green_6'
      | 'bonus_green_1'
      | 'bonus_green_2'
      | 'bonus_green_3'
      | 'bonus_green_4'
      | 'bonus_green_5'
      | 'bonus_green_6'
      | 'referral_bonus_green_1'
      | 'referral_bonus_green_2'
      | 'referral_bonus_green_3'
      | 'referral_bonus_green_4'
      | 'referral_bonus_green_5'
      | 'referral_bonus_green_6'
      | 'cumulative_bonus_green_1'
      | 'cumulative_bonus_green_2'
      | 'cumulative_bonus_green_3'
      | 'cumulative_bonus_green_4'
      | 'cumulative_bonus_green_5'
      | 'cumulative_bonus_green_6'
      | 'buy_desk_tictac_orange_1'
      | 'buy_desk_tictac_orange_2'
      | 'buy_desk_tictac_orange_3'
      | 'buy_desk_tictac_orange_4'
      | 'buy_desk_tictac_orange_5'
      | 'buy_desk_tictac_orange_6'
      | 'buy_clone_tictac_orange_1'
      | 'buy_clone_tictac_orange_2'
      | 'buy_clone_tictac_orange_3'
      | 'buy_clone_tictac_orange_4'
      | 'buy_clone_tictac_orange_5'
      | 'buy_clone_tictac_orange_6'
      | 'bonus_orange_1'
      | 'bonus_orange_2'
      | 'bonus_orange_3'
      | 'bonus_orange_4'
      | 'bonus_orange_5'
      | 'bonus_orange_6'
      | 'referral_bonus_orange_1'
      | 'referral_bonus_orange_2'
      | 'referral_bonus_orange_3'
      | 'referral_bonus_orange_4'
      | 'referral_bonus_orange_5'
      | 'referral_bonus_orange_6'
      | 'cumulative_bonus_orange_1'
      | 'cumulative_bonus_orange_2'
      | 'cumulative_bonus_orange_3'
      | 'cumulative_bonus_orange_4'
      | 'cumulative_bonus_orange_5'
      | 'cumulative_bonus_orange_6'
      | 'buy_pre_order_klondaik_red_gold_1'
      | 'buy_pre_order_klondaik_red_gold_2'
      | 'buy_pre_order_klondaik_red_gold_3'
      | 'buy_pre_order_klondaik_red_gold_4'
      | 'buy_pre_order_klondaik_red_gold_5'
      | 'buy_pre_order_klondaik_red_gold_6'
      | 'buy_pre_order_klondaik_yellow_gold_1'
      | 'buy_pre_order_klondaik_yellow_gold_2'
      | 'buy_pre_order_klondaik_yellow_gold_3'
      | 'buy_pre_order_klondaik_yellow_gold_4'
      | 'buy_pre_order_klondaik_yellow_gold_5'
      | 'buy_pre_order_klondaik_yellow_gold_6'
      | 'buy_pre_order_klondaik_white_gold_1'
      | 'buy_pre_order_klondaik_white_gold_2'
      | 'buy_pre_order_klondaik_white_gold_3'
      | 'buy_pre_order_klondaik_white_gold_4'
      | 'buy_pre_order_klondaik_white_gold_5'
      | 'buy_pre_order_klondaik_white_gold_6'
      | 'buy_desk_klondaik_red_gold_1'
      | 'buy_desk_klondaik_red_gold_2'
      | 'buy_desk_klondaik_red_gold_3'
      | 'buy_desk_klondaik_red_gold_4'
      | 'buy_desk_klondaik_red_gold_5'
      | 'buy_desk_klondaik_red_gold_6'
      | 'buy_clone_klondaik_red_gold_1'
      | 'buy_clone_klondaik_red_gold_2'
      | 'buy_clone_klondaik_red_gold_3'
      | 'buy_clone_klondaik_red_gold_4'
      | 'buy_clone_klondaik_red_gold_5'
      | 'buy_clone_klondaik_red_gold_6'
      | 'bonus_klondaik_red_gold_1'
      | 'bonus_klondaik_red_gold_2'
      | 'bonus_klondaik_red_gold_3'
      | 'bonus_klondaik_red_gold_4'
      | 'bonus_klondaik_red_gold_5'
      | 'bonus_klondaik_red_gold_6'
      | 'referral_bonus_klondaik_red_gold_1'
      | 'referral_bonus_klondaik_red_gold_2'
      | 'referral_bonus_klondaik_red_gold_3'
      | 'referral_bonus_klondaik_red_gold_4'
      | 'referral_bonus_klondaik_red_gold_5'
      | 'referral_bonus_klondaik_red_gold_6'
      | 'cumulative_bonus_klondaik_red_gold_1'
      | 'cumulative_bonus_klondaik_red_gold_2'
      | 'cumulative_bonus_klondaik_red_gold_3'
      | 'cumulative_bonus_klondaik_red_gold_4'
      | 'cumulative_bonus_klondaik_red_gold_5'
      | 'cumulative_bonus_klondaik_red_gold_6'
      | 'buy_desk_klondaik_yellow_gold_1'
      | 'buy_desk_klondaik_yellow_gold_2'
      | 'buy_desk_klondaik_yellow_gold_3'
      | 'buy_desk_klondaik_yellow_gold_4'
      | 'buy_desk_klondaik_yellow_gold_5'
      | 'buy_desk_klondaik_yellow_gold_6'
      | 'buy_clone_klondaik_yellow_gold_1'
      | 'buy_clone_klondaik_yellow_gold_2'
      | 'buy_clone_klondaik_yellow_gold_3'
      | 'buy_clone_klondaik_yellow_gold_4'
      | 'buy_clone_klondaik_yellow_gold_5'
      | 'buy_clone_klondaik_yellow_gold_6'
      | 'bonus_klondaik_yellow_gold_1'
      | 'bonus_klondaik_yellow_gold_2'
      | 'bonus_klondaik_yellow_gold_3'
      | 'bonus_klondaik_yellow_gold_4'
      | 'bonus_klondaik_yellow_gold_5'
      | 'bonus_klondaik_yellow_gold_6'
      | 'referral_bonus_klondaik_yellow_gold_1'
      | 'referral_bonus_klondaik_yellow_gold_2'
      | 'referral_bonus_klondaik_yellow_gold_3'
      | 'referral_bonus_klondaik_yellow_gold_4'
      | 'referral_bonus_klondaik_yellow_gold_5'
      | 'referral_bonus_klondaik_yellow_gold_6'
      | 'cumulative_bonus_klondaik_yellow_gold_1'
      | 'cumulative_bonus_klondaik_yellow_gold_2'
      | 'cumulative_bonus_klondaik_yellow_gold_3'
      | 'cumulative_bonus_klondaik_yellow_gold_4'
      | 'cumulative_bonus_klondaik_yellow_gold_5'
      | 'cumulative_bonus_klondaik_yellow_gold_6'
      | 'buy_desk_klondaik_white_gold_1'
      | 'buy_desk_klondaik_white_gold_2'
      | 'buy_desk_klondaik_white_gold_3'
      | 'buy_desk_klondaik_white_gold_4'
      | 'buy_desk_klondaik_white_gold_5'
      | 'buy_desk_klondaik_white_gold_6'
      | 'buy_clone_klondaik_white_gold_1'
      | 'buy_clone_klondaik_white_gold_2'
      | 'buy_clone_klondaik_white_gold_3'
      | 'buy_clone_klondaik_white_gold_4'
      | 'buy_clone_klondaik_white_gold_5'
      | 'buy_clone_klondaik_white_gold_6'
      | 'bonus_klondaik_white_gold_1'
      | 'bonus_klondaik_white_gold_2'
      | 'bonus_klondaik_white_gold_3'
      | 'bonus_klondaik_white_gold_4'
      | 'bonus_klondaik_white_gold_5'
      | 'bonus_klondaik_white_gold_6'
      | 'referral_bonus_klondaik_white_gold_1'
      | 'referral_bonus_klondaik_white_gold_2'
      | 'referral_bonus_klondaik_white_gold_3'
      | 'referral_bonus_klondaik_white_gold_4'
      | 'referral_bonus_klondaik_white_gold_5'
      | 'referral_bonus_klondaik_white_gold_6'
      | 'cumulative_bonus_klondaik_white_gold_1'
      | 'cumulative_bonus_klondaik_white_gold_2'
      | 'cumulative_bonus_klondaik_white_gold_3'
      | 'cumulative_bonus_klondaik_white_gold_4'
      | 'cumulative_bonus_klondaik_white_gold_5'
      | 'cumulative_bonus_klondaik_white_gold_6'
    >,
    direction?: 1 | -1,
    createdAtStart: string = '',
    createdAtEnd: string = '',
    sort?: 'created' | 'username',
    order?: 'ASC' | 'DESC'
  ): CancelablePromise<{
    status: boolean;
    data: {
      total: number;
      items: Array<DetailTransactionViewModel>;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/accounting/transaction/list',
      query: {
        offset: offset,
        limit: limit,
        username: username,
        'status[]': status,
        'type[]': type,
        direction: direction,
        'createdAt[start]': createdAtStart,
        'createdAt[end]': createdAtEnd,
        sort: sort,
        order: order,
      },
      errors: {
        403: `Access Denied`,
      },
    });
  }

  /**
   * Gets detailed information about the transaction.
   * @param transactionId Transaction ID
   * @returns any Returns detailed information about the transaction.
   * @throws ApiError
   */
  public static getOneDetailTransaction(transactionId: string): CancelablePromise<{
    status: boolean;
    data: DetailTransactionViewModel;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/accounting/transaction/detail/{transactionId}',
      path: {
        transactionId: transactionId,
      },
      errors: {
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Updates the transaction
   * @param transactionId Transaction ID
   * @param requestBody New transaction values
   * @returns void
   * @throws ApiError
   */
  public static updateTransaction(
    transactionId: string,
    requestBody: UpdateTransactionRequest
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/accounting/transaction/update/{transactionId}',
      path: {
        transactionId: transactionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        403: `Access Denied`,
        404: `Not Found`,
      },
    });
  }
}
